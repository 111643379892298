<template>
  <div v-if="store" class="min-h-screen">
    <div class="relative">
      <StoresSlider :store="store" />
    </div>

    <StoresMetaTile v-if="!isMobileOrTablet" :store="store" />

    <HomeSwiperMaterials
      :branch_id="currentBranchId"
      :title="t('offers_discounts')"
      :route-title="{ path: '/offers' }"
      :fetch-params="{
        store_id: store.id,
        offersOnly: true,
        branch_id: currentBranchId
      }"
    />

    <HomeOffersGroupSlider
      :query="{
        'store_ids[]': [store.id],
        limit: 1000,
        branch_id: currentBranchId
      }"
    />

    <MaterialsCatogriesWithSupportFilter
      :support-filter="store.support_filters === 1"
      :use-categories-domain="true"
      :fetch-params="{ store_id: store.id, branch_id: currentBranchId }"
    />

    <MaterialsGrid
      class="mb-5 sela-container"
      with-query-params
      :fetch-params="{ store_id: store.id, branch_id: currentBranchId }"
      manual
      :items-per-page="20"
    />

    <HomeSwiperMaterials
      key-cache="featured_materials"
      :branch_id="currentBranchId"
      :title="t('featured_materials')"
      :fetch-params="{
        is_featured: true,
        store_id: store.id,
        branch_id: currentBranchId
      }"
    />
    <HomeSwiperMaterials
      v-if="store?.show_newest_materials"
      :title="t('Recently_added')"
      :fetch-params="{ order_by_latest: 1, branch_id: currentBranchId, store_id: store.id, }"
    />
    <HomeSwiperMaterials
      v-if="store?.show_most_requested_materials"
      :title="t('the_most_demanded')"
      route="/agent/get-top-material-for-each-category"
      :fetch-params="{ branch_id: currentBranchId, store_id: store.id, }"
    />
    <HomeSwiperMaterials
      v-if="store?.show_most_viewed_materials"
      :title="t('most_watched')"
      :fetch-params="{ order_by_view_number: 1, branch_id: currentBranchId, store_id: store.id, }"
    />
    <StoresRelatedStores :related-stores="store.related_stores" />
    <HomeAdSecondarySwiper
      :ads="{ error: errorAds, items: ads?.adsStore || [] }"
    />
  </div>
</template>

<script setup lang="ts">
import type { Ad } from '~~/composables/useMenuModel'

const { url } = useDomainHost()
const { store } = useDomainState()
const route = useRoute()
const { isMobileOrTablet } = useDevice()
const currentBranchId = useBranchId()
const { adModel } = useMenuModel()
const { handleReponseApi } = useCartStore()
const { setTableToStore } = useCartMethods()
const { t } = useI18n()

const { data: ads, error: errorAds } = await useApiFetch(
  `/announcements-store/${store.value!.id}`,
  {
    query: { branch_id: currentBranchId },
    transform: (
      data: SuccessResponse<{
        announcementsStore: Ad[]
        announcements: Ad[]
      }>
    ) => {
      const adsStore = data.data?.announcementsStore?.map(adModel)

      return {
        adsStore
      }
    }
  }
)

async function setTableStore() {
  if (route.query.table_id && store.value?.id) {
    const dataApi = await setTableToStore(store.value?.id, route.query.table_id)
    if (
      handleReponseApi(dataApi, store.value?.id.toString()) &&
      !store.value.is_user_have_table
    ) {
      window.location.reload()
    }
  }
}

onMounted(() => {
  nextTick(() => {
    setTableStore()
  })
})

useServerSeoMeta(
  useOgMeta(url, store.value?.name, store.value?.about, store.value?.thumb)
)

useHead({
  title: store.value?.name,
  meta: [
    { hid: 'description', name: 'description', content: store.value?.about }
  ],
  link: [useSeoCanonical(url + '/')],
  script: [useSeoBreadcrumb([useSeoBreadcrumbItem(1, store.value?.name, url)])]
})
</script>
